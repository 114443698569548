import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/frogs",
    name: "FrogListener",
    component() {
      return import(/* webpackChunkName: "frog-listener" */ "../views/frog-listener.vue");
    },
  },
  {
    path: "/licenses",
    name: "LicensePage",
    component() {
      return import(/* webpackChunkName: "license-page" */ "../views/license-page.vue");
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/frogs",
  },
];

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
});

export default router;
