<template>
  <div id="app">
    <router-view />
    <router-link class="licenseLink" to="/licenses">Licenses</router-link>
  </div>
</template>

<style>
body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  color: #2c3e50;
}
</style>
